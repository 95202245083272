<template>
  <div class="operatingRecordPage">
    <div class="facilityBox">
      <!-- 搜索框 -->
      <el-form ref="searchForm" inline :model="searchForm" style="margin-top:5px">
        <el-form-item label="操作内容" prop="content" label-width="80px">
          <el-input v-model="searchForm.content" placeholder="检索内容"></el-input>
        </el-form-item>
        <el-form-item label="操作类型" prop="" label-width="80px">
          <el-select v-model="searchForm.actionType" @change="search">
            <el-option v-for="item in opereationTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="Remark" label-width="80px">
          <el-input v-model="searchForm.Remark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="操作时间：" prop="time" label-width="100px">
          <el-date-picker v-model="searchForm.time" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
        </el-form-item>
        <el-button type="primary" icon="el-icon-zoom-in" @click="search" :loading="pagination.loading">搜索
        </el-button>
        <el-button type="primary" icon="el-icon-delete" @click="$refs['searchForm'].resetFields()">清除
        </el-button>
      </el-form>
      <!-- 列表 -->
      <el-table :data="pagination.list" v-loading="pagination.loading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column align="center" prop="ActionTypeName" label="操作类型" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Content" label="操作内容" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CreateTime" label="操作时间" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="UserName" label="操作人" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseName" label="企业名称" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openContentDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background style="margin-top: 16px;" @current-change="paginationChange" @size-change="sizeChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>

      <el-dialog title="操作内容详情" :visible.sync="flag.contentDetailDialog">
        <p v-if="currentTableItem != null">{{ currentTableItem.Content }}</p>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { actionLogs } from '@/api/platform/operatingRecord/index'
export default {
  data() {
    return {
      flag: {
        contentDetailDialog: false, //操作内容详情
      },
      opereationTypeList: [
        { label: '删除企业司机', value: 6 },
        { label: '删除运力池司机', value: 7 },
        { label: '风控修改记录', value: 10 },
        { label: '认证司机', value: 11 },
        { label: '认证车辆', value: 12 }
      ], //操作类型
      searchForm: {
        content: '', //检索内容
        actionType: 6, //操作类型
        ascriptionUserID: 1, //企业id 不传查询全部
        time: ["", ""], // 时间
        Remark: '', // 操作人
      },
      pagination: {
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
        list: [],
      },
      currentTableItem: null, //当前整在操作的表格的对象
    }
  },
  methods: {
    //搜索
    search() {
      this.pagination.loading = true
      let params = {
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
        content: this.searchForm.content,
        actionType: this.searchForm.actionType,
        Remark: this.searchForm.Remark,
        StartTime: this.searchForm.time ? this.searchForm.time[0] : '',
        EndTime: this.searchForm.time ? this.searchForm.time[1] : '',
      }
      actionLogs(params).then(res => {
        this.pagination.list = res.data.DataList
        this.pagination.total = res.data.TotalCount
      }).finally(() => {
        this.pagination.loading = false
      })
    },
    //获取操作记录
    actionLogs() {
      this.pagination.loading = true
      let params = {
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
        actionType: this.searchForm.actionType,
        Remark: this.searchForm.Remark,
        StartTime: this.searchForm.time ? this.searchForm.time[0] : '',
        EndTime: this.searchForm.time ? this.searchForm.time[1] : '',
      }
      actionLogs(params).then(res => {
        this.pagination.list = res.data.DataList
        this.pagination.total = res.data.TotalCount
      }).finally(() => {
        this.pagination.loading = false
      })
    },
    //分页改变
    paginationChange(e) {
      this.pagination.page = e
      this.search()
    },
    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e
      this.search()
    },
    //查看内容详情
    openContentDetail(item) {
      this.currentTableItem = item
      this.flag.contentDetailDialog = true
    }
  },
  created() {
    this.actionLogs()
  },
}
</script>

<style lang="scss" scoped>
</style>